import React, {useEffect, useState} from "react";
import getCenters from "../center/GetCenters";
import './ServicesMain.css';
import BonoList from "./bono/BonoList";
import ServiceList from "./service/ServiceList";
import {deleteService, getAllServices} from "../../services/ApiServices";
import {deleteBono, getAllBonos} from "../../services/ApiBonos";
import plusIcon from "../../assets/añadir.svg";
import ServiceForm from "./service/ServiceForm";
import BonoForm from "./bono/BonoForm";

const ServicesMain = () => {
    const {centers, selectedCenter, setSelectedCenter} = getCenters();
    const [services, setServices] = useState([]);
    const [bonos, setBonos] = useState([]);

    const [serviceModalMode, setServiceModalMode] = useState("create");
    const [serviceFormVisible, setServiceFormVisible] = useState(false);
    const [serviceData, setServiceData] = useState(null);

    const [bonoModalMode, setBonoModalMode] = useState("create");
    const [bonoFormVisible, setBonoFormVisible] = useState(false);
    const [bonoData, setBonoData] = useState(null);

    const fetchServices = async (centerId) => {
        console.log("FetchServices_centerId: ", centerId)
        const servicesData = await getAllServices({centerId: centerId, available: true});
        setServices(servicesData);
    };

    const fetchBonos = async (centerId) => {
        const bonosData = await getAllBonos({centerId: centerId, available: true});
        setBonos(bonosData);
    };

    useEffect(() => {
        if (selectedCenter) {
            fetchServices(selectedCenter.id);
            fetchBonos(selectedCenter.id);
        }
    }, [selectedCenter]);

    const handleServicePlusClick = () => {
        setServiceModalMode("create");
        setServiceData(null);
        setServiceFormVisible(true);
    };

    const handleServiceEditClick = (service) => {
        setServiceModalMode("edit");
        setServiceData(service);
        setServiceFormVisible(true);
    };

    const handleCloseServiceModal = () => {
        setServiceFormVisible(false);
        setServiceData(null)
    };

    const handleBonoPlusClick = () => {
        setBonoModalMode("create");
        setBonoData(null);
        setBonoFormVisible(true);
    };

    const handleBonoEditClick = (service) => {
        setBonoModalMode("edit");
        setBonoData(service);
        setBonoFormVisible(true);
    };

    const handleCloseBonoModal = () => {
        setBonoFormVisible(false);
        setBonoData(null)
    };

    const handleServiceRemoveClick = (service) => {
        console.log("Remove service: ", service);
        // if (service.removable) {
            deleteService(service.id).then(() => fetchServices(selectedCenter.id));
        // }
    }

    const handleBonoRemoveClick = (bono) => {
        console.log("Remove bono: ", bono);
        // if (bono.removable) {
            deleteBono(bono.id).then(() => fetchBonos(selectedCenter.id));
        // }
    }

    return (
        <div className="services-main-container">
            <div className="centers-container">
                {centers.slice(0).map((center) => (
                    <div
                        key={center.id}
                        onClick={() => setSelectedCenter(center)}
                        className={`center-item ${selectedCenter === center ? 'selected' : ''}`}
                    >
                        {center.name}
                    </div>
                ))}
            </div>
            <div className="lists-container">
                <div className="list-half">
                    <div className="title-container">
                        <h2>Servicios</h2>
                        <img src={plusIcon} alt="Add" className="plus-icon" onClick={handleServicePlusClick}/>
                    </div>
                    <ServiceList selectedCenter={selectedCenter} services={services}
                                 onEditClick={handleServiceEditClick} onDeleteClick={handleServiceRemoveClick}/>
                </div>
                <div className="list-half">
                    <div className="title-container">
                        <h2>Bonos</h2>
                        <img src={plusIcon} alt="Add" className="plus-icon" onClick={handleBonoPlusClick}/>
                    </div>
                    <BonoList selectedCenter={selectedCenter} bonos={bonos} centers={centers}
                              onEditClick={handleBonoEditClick} onDeleteClick={handleBonoRemoveClick}/>
                </div>
            </div>
            <ServiceForm show={serviceFormVisible} handleClose={handleCloseServiceModal} mode={serviceModalMode}
                         data={serviceData} centers={centers} selectedCenter={selectedCenter}
                         onServiceChange={fetchServices}/>
            <BonoForm show={bonoFormVisible} handleClose={handleCloseBonoModal} mode={bonoModalMode} data={bonoData}
                      centers={centers} selectedCenter={selectedCenter}
                      onBonoChange={fetchBonos}/>
        </div>
    );
};

export default ServicesMain;
import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import './CenterList.css';
import centerImg from "../../assets/e-motion.jfif";
import optsIcon from "../../assets/puntitos.svg";
import {getCenterById} from "../../services/ApiCenters";
import novaIcon from "../../assets/favicon.svg";

const UserCenter = () => {
    const [center, setCenters] = useState(null);
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(center?.image || novaIcon);

    useEffect(() => {
        fetchUserCenter();
    }, []);

    const fetchUserCenter = async () => {
        let id = localStorage.getItem('centerId');
        let newVar = await getCenterById(id);
        setCenters(newVar)
    }

    return (
        <>
            <Row>
                <Col key={center?.id} xs={12} sm={6} md={4} lg={3}>
                    {/*<Col key={center.id} xs={2} sm={2} md={2} lg={2} xl={2}>*/}
                    {/*<Card className="text-center mb-4 card-custom" style={{marginRight: '0px'}}>*/}
                    <Card className="text-center mb-4 card-custom mx-auto" style={{marginRight: '0px'}}>
                        <div className="icon-container">
                            <img src={optsIcon} alt="Add" className="opts-icon"/>
                        </div>
                        <Card.Img
                            variant="top"
                            src={centerImg} // Use the imported image
                            alt={center?.name}
                            className="card-img-top"
                        />
                        <Card.Body>
                            <Card.Title>{center?.name}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{center?.email}<br/>{center?.tlf}
                            </Card.Subtitle>
                            <Card.Text>
                                {center?.address}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

export default UserCenter;


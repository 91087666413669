import {useEffect, useState} from "react";
import {getAllCenters} from "../../services/ApiCenters";

const GetCenters = () => {
    const [centers, setCenters] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);

    const get = async () => {
        /*const apiUrl = `${process.env.REACT_APP_SERVER_URL}/center`;
        const response = await fetch(apiUrl, {
            method: 'GET',
            redirect: "manual",

        });

        if (response.type === 'opaqueredirect') {
            window.location.href = response.url;
            return;
        }*/

        const data = await getAllCenters();
        setCenters(data);
        if (data.length > 0) {
            setSelectedCenter(data[0]);
        }
    };

    useEffect(() => {
        get();
    }, []); // Add an empty dependency array here

    return {centers, selectedCenter, setSelectedCenter, setCenters};
};

export default GetCenters;
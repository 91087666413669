import apiFetch from "./api";

const apiUrl = `${process.env.REACT_APP_SERVER_URL}/class-sessions`;

const getEvents = async (params = {}) => {
    // const queryString = "?" + new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}/events${queryString}`,
        {
            method: 'GET',
            redirect: "manual",

        });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
}

const getMyEvents = async (params = {}) => {
    // const queryString = "?" + new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}/my-sessions${queryString}`,
        {
            method: 'GET',
            redirect: "manual",

        });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
}

const getAvailableEvents = async (params = {}) => {
    // const queryString = "?" + new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}/available/events${queryString}`,
        {
            method: 'GET',
            redirect: "manual",

        });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
}

const cancelSession = async (userId, eventId) => {

    const response = await apiFetch(`${apiUrl}/${eventId}/cancel/user/${userId}`, {
        method: 'DELETE',
        redirect: "manual",

    });
}

const reserveSession = async (userId, eventId) => {

    const response = await apiFetch(`${apiUrl}/${eventId}/reserve/user/${userId}`, {
        method: 'PUT',
        redirect: "manual",
    });
}

const getSessionById = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {method: 'GET', redirect: "manual",});
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const postSession = async (body) => {
    const response = await apiFetch(`${apiUrl}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const putSession = async (id, body) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return;
};

const putSerie = async (id, body) => {
    const response = await apiFetch(`${apiUrl}/serie/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return; //.json();
};

const deleteSession = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response; //.json();
};

const deleteSerie = async (id) => {
    const response = await apiFetch(`${apiUrl}/serie/${id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response; //.json();
};

const addAttendee = async (id, userId) => {
    const response = await apiFetch(`${apiUrl}/${id}/attendee/${userId}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return;
};

const removeAttendee = async (id, userId) => {
    const response = await apiFetch(`${apiUrl}/${id}/attendee/${userId}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return;
};

const changeStatusAttendee = async (id, userId) => {
    const response = await apiFetch(`${apiUrl}/${id}/attendee/${userId}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return;
};

const addWaitlist = async (id, userId) => {
    const response = await apiFetch(`${apiUrl}/${id}/waitlist/${userId}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return;
};

const removeWaitlist = async (id, userId) => {
    const response = await apiFetch(`${apiUrl}/${id}/waitlist/${userId}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return;
};


export {
    getEvents,
    getMyEvents,
    postSession,
    getSessionById,
    putSerie,
    putSession,
    deleteSession,
    deleteSerie,
    cancelSession,
    reserveSession,
    removeAttendee,
    addAttendee,
    addWaitlist,
    removeWaitlist,
    getAvailableEvents,
    changeStatusAttendee
};

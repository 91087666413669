import {Button, Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import "../CardListStyle.css";
import React, {useEffect, useState} from "react";
import novaIcon from "../../assets/favicon.svg";
import {getAllBonos} from "../../services/ApiBonos";
import {getUserById, updateUserPayment} from "../../services/ApiUsers";

const Payments = () => {

    const [bono, setBono] = useState(null);
    const [bonos, setBonos] = useState([]);
    const [payments, setPayments] = useState([
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pendiente'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'}
    ]);

    useEffect(() => {
        let item = localStorage.getItem("userId");
        console.log("user id bono: ", item)
        let client = getUserById(3)
            .then((user) => setBono(user.bono))
        // console.log("Client----: ", client);
        setBono(client.bono);
        // getAllBonos({centerId: client.center.id}).then((data) => {
        //     setBonos(data)
        // });
        // setSelectedBonoId(client.bono?.id);
        console.log("bonoooo", bono)
    }, []);

    /*useEffect(() => {
        console.log("Bono update: ", selectedBonoId);
        console.log("Bonos: ", bonos);
        let asd = bonos.find(b => b.id === parseInt(selectedBonoId, 10));
        // let asd = bonos.find(b => b.id === selectedBonoId);
        console.log("Bono update: ", asd);
        setBono(asd);
    }, [selectedBonoId]);*/

    const onSubmit = (e) => {
        e.preventDefault();
        let payment = {
            // bonoId: selectedBonoId,
            paymentMethodId: 1
        };
        // updateUserPayment(client.id, payment).then(fetchClients());
    }

    return (
        <>
            {/*<Row md={6} className="justify-content-md-center">*/}
            {/*<Col >*/}
            <Row md={12} className="text-right d-flex justify-content-center">
                <Card className="card-custom text-center"
                      style={{
                          width: '17rem',
                          height: '15rem',
                          minWidth: '15rem',
                          minHeight: '10rem',
                          marginRight: '0'
                      }}>
                    <Card.Body>
                        <Card.Img variant="top" src={bono?.image == null ? novaIcon : bono.image}
                                  alt={'img'} className="card-img-top" style={{width: '5rem', height: '5rem'}}/>
                        <Card.Title>{bono !== null ? bono?.name : " "}</Card.Title>
                        <Card.Text>
                            {bono?.center.tlf}<br/>
                            {bono?.center.email}<br/>
                            {bono?.center.address}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
            <br/>

            <Row md={12} className="text-right d-flex justify-content-center">
                <Card className="card-custom text-center"
                      style={{
                          width: '17rem',
                          height: '7rem',
                          minWidth: '15rem',
                          minHeight: '7rem',
                          marginRight: '0'
                      }}>
                    <Card.Body>
                        <Card.Title style={{fontSize: '1rem'}}>Método de pago</Card.Title>
                        <Card.Text>
                            Cuenta bancaria: <br/>
                            **** **** **** **** **** 1234
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
            {/*</Col>*/}
<br/>
            {/*</Row>*/}
            <Row md={12} className="text-right d-flex justify-content-center">
                {/*<Col style={{width: '70%'}}>*/}
                {/*<Row>*/}
                <Form.Group controlId="formPayments">
                    <Form.Label>Historial de pagos</Form.Label>

                    <ListGroup as="ol" style={{maxHeight: '25rem', overflowY: 'auto', fontSize: '0.5rem'}}>
                        {payments.map((payment) => (

                            <ListGroup.Item as="li" key={payment.id}>
                                <Row md={3} className="justify-content-md-center">
                                    <Col style={{width: '40%'}}>
                                        {payment.bono}
                                    </Col>
                                    <Col style={{width: '20%'}}>
                                        {payment.method}
                                    </Col>
                                    <Col style={{width: '20%'}}>
                                        {payment.date}
                                    </Col>

                                    <Col style={{
                                        width: '20%',
                                        color: payment.status === 'Pagado' ? 'green' : payment.status === 'Pendiente' ? 'orange' : 'black'
                                    }}>
                                        {payment.status}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Form.Group>
                {/*</Row>*/}
                {/*</Col>*/}
            </Row>

        </>
    )
}
export default Payments;
import apiFetch from "./api";

const apiUrl = `${process.env.REACT_APP_SERVER_URL}/bono`;

const getAllBonos = async (params = {}) => {
    // const queryString = "?" + new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}${queryString}`, {
        method: 'GET',
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const getBonoById = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {method: 'GET', redirect: "manual",});
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const createBono = async (bono) => {
    const response = await apiFetch(apiUrl, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(bono),
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const updateBono = async (id, bono) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(bono),
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const deleteBono = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'DELETE',
        redirect: "manual",

    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return;
};

export {getAllBonos, getBonoById, createBono, updateBono, deleteBono};
import {Card} from "react-bootstrap";
import novaIcon from "../../../assets/favicon.svg";
import React, {useState} from "react";
import {FaTrash} from "react-icons/fa";

const ServiceList = ({selectedCenter, services, onEditClick, onDeleteClick}) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const handleIconClick = () => {
        setMenuVisible(!menuVisible);
    };


    return (
        <div className="custom-list-container">

            <div className="row">
                {services && services.map((service) => (
                    <div className="col-12 col-md-6 mb-4" key={service.id}>
                        <Card className="text-center mb-4 card-custom ">
                            {/*<OverlayTrigger*/}
                            {/*    placement="top" // Puedes cambiar la posición del tooltip*/}
                            {/*    overlay={*/}
                            {/*        <Tooltip id={`tooltip-${service.id}`}>*/}
                            {/*            {service.removable ? "Eliminar este servicio" : "Este servicio tiene sesiones futuras programadas"}*/}
                            {/*        </Tooltip>*/}
                            {/*    }*/}
                            {/*>*/}
                                <div className="icon-container">
                                    <FaTrash variant="danger"
                                             style={{
                                                 width: '15px',
                                                 height: '15px',
                                                 color: '#dc3545'
                                             }}
                                             onClick={() => onDeleteClick(service)}/>
                                </div>
                            {/*</OverlayTrigger>*/}
                            <Card.Img variant="top" src={service.image == null ? novaIcon : service.image}
                                      alt={service.name} className="card-img-top" onClick={() => onEditClick(service)}/>
                            <Card.Body onClick={() => onEditClick(service)}>
                                <Card.Title>{service.name}</Card.Title>
                                {/*<Card.Subtitle className="mb-2 text-muted">{service.center.tlf}<br />{service.center.email}</Card.Subtitle>*/}
                                {/*<Card.Text>*/}

                                {/*</Card.Text>*/}
                                <Card.Text style={{paddingTop: 'none'}}>

                                    {/*{service?.centers?.length > 0 && service.centers[0].tlf}<br/>*/}
                                    {/*{service?.centers?.length > 0 && service.centers[0].email}<br/>*/}
                                    {/*{service?.centers?.length > 0 && service.centers[0].address}*/}
                                    {/*<div style={{*/}
                                    {/*    justifyContent: 'space-between', alignItems: 'start',*/}
                                    {/*    textAlign: 'left'*/}
                                    {/*}}>*/}

                                    <span style={{fontWeight: 'bold'}}>Capacidad:</span> {service.capacity} px.<br/>
                                    {/*<span style={{fontWeight: 'bold'}}>Centros:</span> {service.centers.map((center) => center.name).join(', ')}<br/>*/}
                                    {/*<hr/>*/}

                                        <span style={{fontWeight: 'bold'}}>Duración:</span> {service.duration} mins

                                        <br/>
                                        <span style={{fontWeight: 'bold'}}>Entrenador: </span>{service.trainer?.name}
                                    {/*</div>*/}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceList;
import './App.css';
import React, {useEffect, useState} from "react";
import {Menu} from "./component/main/Menu";
import {Navigate, Route, Routes} from "react-router-dom";
import {HeaderBar} from "./component/main/HeaderBar";
import ServicesMain from "./component/servicesPages/ServicesMain";
import Login from "./security/Login";
import Callback from "./security/Callback";
import ClientList from "./component/client/ClientList";
import TrainerList from "./component/trainer/TrainerList";
import MyCalendar from "./component/calendar/MyCalendar";
import UserCenter from "./component/center/UserCenter";
import CenterList from "./component/center/CenterList";
import ProfileOptions from "./component/profile/ProfileOptions";
import ProfileUser from "./component/profile/ProfileUser";
import CalendarAdmin from "./component/calendar/CalendarAdmin";
import ProfileAdmin from "./component/client/ProfileAdmin";
import PaymentsUser from "./component/profile/PaymentsUser";
import UserLessonsCalendar from "./component/calendar/AvailableLessonsCalendar";
// import authService from "./security/AuthService";
// import tokenRefresher from "./security/AuthService";


const PrivateRoute = ({element: Element, ...rest}) => {
    // console.log("PrivateRoute", localStorage.getItem('access_token'))
    const isAuthenticated = localStorage.getItem('access_token') != null;
    return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login"/>;
};

function App() {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedPage, setSelectedPage] = useState("Centers");
    const [selectedPageTitle, setSelectedPageTitle] = useState("Centros");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const getCalendarComponent = () => {
        const roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : [];
        return roles.includes('USER') ? UserLessonsCalendar : CalendarAdmin;
        // return roles.includes('ADMIN') ? CalendarAdmin : UserLessonsCalendar;
    };

    const getCenterComponent = () => {
        const roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : [];
        return roles.includes('USER') ? UserCenter : CenterList;
        // return roles.includes('ADMIN') ? CenterList : UserCenter;
    }

    const getProfileComponent = () => {
        const roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : [];
        return roles.includes('USER') ? ProfileUser : ProfileAdmin;
        // return roles.includes('ADMIN') ? CenterList : UserCenter;
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="app-container d-flex">
            <div className="inner-container">
                <Menu
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setSelectedPageTitle={setSelectedPageTitle}
                />
                {/*<MainContainer collapsed={collapsed} selectedPage={selectedPage} username={userName}/>*/}

                <div className={`main-content ${collapsed ? "expanded" : ""}`}>
                    {/*<HeaderBar selectedPage={selectedPageTitle} username={userName}/>*/}
                    {localStorage.getItem('access_token') &&
                        <HeaderBar selectedPage={selectedPageTitle} isMobile={isMobile}/>}
                    <h2 className={"d-flex"} style={{marginTop: '5rem', marginLeft: '1rem'}}>{selectedPageTitle}</h2>
                    <div className={"content"}>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/" element={<Navigate to={isMobile ? "/calendar" : "/centers"}/>}/>
                            <Route path="/callback" element={<Callback/>}/>
                            {/*<Route path="/calendar" element={<CalendarAdmin/>}/>*/}
                            <Route path="/calendar" element={<PrivateRoute element={getCalendarComponent()}/>}/>
                            <Route path="/my-calendar" element={<PrivateRoute element={MyCalendar}/>}/>
                            <Route path="/profile" element={<PrivateRoute element={getProfileComponent()}/>}/>
                            <Route path="/profile-menu" element={<PrivateRoute element={ProfileOptions}/>}/>
                            <Route path="/my-bono" element={<PrivateRoute element={PaymentsUser}/>}/>
                            {/*<Route path="/centers" element={<CenterList/>}/>*/}
                            <Route path="/centers" element={<PrivateRoute element={getCenterComponent()}/>}/>
                            <Route path="/services" element={<PrivateRoute element={ServicesMain}/>}/>
                            <Route path="/trainers" element={<PrivateRoute element={TrainerList}/>}/>
                            <Route path="/clients" element={<PrivateRoute element={ClientList}/>}/>
                            <Route path="/statistics" element={<div>En construcción -> {selectedPage}</div>}/>
                            <Route path="/billing" element={<div>En construcción -> {selectedPage}</div>}/>
                            <Route path="*" element={<div>Página no encontrada</div>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;

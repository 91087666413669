import {Card} from "react-bootstrap";
import React, {useState} from "react";
import '../../CardListStyle.css';
import novaIcon from "../../../assets/favicon.svg";
import {FaTrash} from "react-icons/fa";

const BonoList = ({selectedCenter, bonos, onEditClick, onDeleteClick}) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleIconClick = () => {
        setMenuVisible(!menuVisible);
    };


    return (
        <div className="bono-list-container">
            <div className="row">
                {bonos && bonos.map((bono) => (
                    <div className="col-12 col-md-6 mb-4" key={bono.id}>
                        <Card className="text-center mb-4 card-custom card-custom">
                            {/*<OverlayTrigger*/}
                            {/*    placement="top" // Puedes cambiar la posición del tooltip*/}
                            {/*    overlay={*/}
                            {/*        <Tooltip id={`tooltip-${bono.id}`}>*/}
                            {/*            {bono.removable ? "Eliminar bono" : "Este bono tiene clientes inscritos"}*/}
                            {/*        </Tooltip>*/}
                            {/*    }*/}
                            {/*>*/}
                                <div className="icon-container" onClick={() => onDeleteClick(bono)}>
                                    <FaTrash variant="danger"
                                             style={{
                                                 // cursor: bono.removable ? 'pointer' : 'not-allowed',
                                                 width: '15px',
                                                 height: '15px',
                                                 // color: bono.removable ? '#dc3545' : 'gray',
                                                 color: '#dc3545',
                                             }}
                                        // title={service.removable ? "Eliminar este servicio" : "Este servicio tiene sesiones futuras programadas."}
                                             onClick={() => setShowConfirmModal(true)}/>
                                </div>

                            {/*</OverlayTrigger>*/}
                            <Card.Img variant="top" src={bono.image == null ? novaIcon : bono.image}
                                      alt={bono.name} className="card-img-top" onClick={() => onEditClick(bono)}/>
                            <Card.Body onClick={() => onEditClick(bono)}>
                                <Card.Title>{bono.name}</Card.Title>
                                {/*<Card.Subtitle className="mb-2 text-muted">{service.center.tlf}<br />{service.center.email}</Card.Subtitle>*/}
                                <Card.Text>
                                    <span style={{fontWeight: 'bold'}}>Precio:</span> {bono?.price} €<br/>
                                    <span
                                        style={{fontWeight: 'bold'}}>Días:</span> {bono?.weekDays.map((day) => day.name).join(', ')}<br/>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>

        </div>

    );
};

export default BonoList;

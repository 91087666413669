import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import novaIcon from "../../assets/favicon.svg";
import {createCenter, updateCenter} from "../../services/ApiCenters";

const CenterForm = ({show, center, onClose, mode, fetchCenters}) => {
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [tlf, setTlf] = useState("");
    const [email, setEmail] = useState("");
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(center?.image || novaIcon);

    const handleOnClose = () => {
        onClose();
    }

    useEffect(() => {
        console.log("Center: ", center);
        if (mode === 'edit') {
            setName(center?.name);
            setAddress(center?.address);
            setTlf(center?.tlf);
            setEmail(center?.email);
            setImage(center?.image)
            setPreview(center?.image);
        } else {
            setName("");
            setAddress("");
            setTlf("");
            setEmail("");
            setImage(null);
            setPreview(null);
        }
    }, [mode, center]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        console.log("File: ", file);

        loadImagePreview(file);
        console.log("Preview ", preview);
    };

    const loadImagePreview = (image) => {
        // Vista previa de la imagen
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (image) {
            reader.readAsDataURL(image);
        }
        return reader.result;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const dataForm = {
            name,
            address,
            tlf,
            email,
            image: preview
        };
        console.log("Form Data: ", dataForm);
        console.log("Mode: ", mode);
        if (mode === 'create') {
            createCenter(dataForm).then(() => {
                fetchCenters();
                onClose();
            });
        } else {
            updateCenter(center.id, dataForm).then(() => {
                fetchCenters();
                onClose();
            });
        }
    }

    return (
        <Modal show={show} onHide={onClose} dialogClassName="form-modal" size={'xl'} centered backdrop="static"
               keyboard={false} contentClassName={"modal-content-center"}>

            <Modal.Body className="modal-body-scrollable" style={{minHeight: '600px'}}>
                <Form>
                    <Form.Group controlId="formImagen">
                        <Form.Label>Imagen del centro</Form.Label>
                        <div className="mt-3 image-div">
                            <img src={image ? preview : novaIcon} alt="Vista previa" style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}/>
                        </div>
                        <Form.Control className={"sm"}
                                      type="file"
                                      onChange={(e => handleFileChange(e))}
                                      accept="image/*"
                        />
                    </Form.Group>
                    <Form.Group controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" placeholder="Nombre del centro" value={name}
                                      onChange={(e) => setName(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control type="text" placeholder="Dirección del centro" value={address}
                                      onChange={(e) => setAddress(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control type="text" placeholder="Teléfono del centro" value={tlf}
                                      onChange={(e) => setTlf(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email del centro" value={email}
                                      onChange={(e) => setEmail(e.target.value)} required/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*    <button className="btn btn-primary" onClick={handleOnClose}>Guardar</button>*/}
            {/*</Modal.Footer>*/}
            <Modal.Footer>
                <Button variant="secondary" className="cancel-btn" onClick={onClose}>
                    Cancelar
                </Button>
                <Button variant="primary" className="save-btn" type="submit" onClick={e => handleSubmit(e)}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default CenterForm;
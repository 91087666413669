// frontend/src/components/UpdateModal.js
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const UpdateModal = ({show, handleClose, handleUpdate}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Available</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                A new version of the application is available. Do you want to update?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleUpdate}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateModal;
import {Card, Col, Container, Row} from "react-bootstrap";
import moneyIcon from "../../assets/dinero-lineas.svg";
import userIcon from "../../assets/usuario.svg";
import graphIcon from "../../assets/estadisticas.svg";
import checkIcon from "../../assets/check.svg";
import React from "react";
import "./Profile.css";
import {useNavigate} from "react-router-dom";

const ProfileOptions = () => {
    const navigate = useNavigate();
    // const [page, setPage] = useNavigate("");

    const handleNavClick = (page) => {
        navigate('/' + page);
    }

    return (
        <Container className='profile-list-container'>
            <Row style={{width: '100%'}}>
                <Col md={6} style={{width: '48%'}}>
                    <Card className="text-center mb-4 profile-card"
                          onClick={() => handleNavClick("profile")}>
                        <Card.Img variant="top" src={userIcon} alt="Editar datos"
                                  className="card-img-top card-img-profile" style={{borderRadius: '5em'}}/>
                        <Card.Body>
                            <Card.Text className="card-text-profile">Editar datos</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} style={{width: '48%', marginLeft: '0.5em'}}>
                    <Card className="text-center mb-4 profile-card"
                          onClick={() => console.log("Historial de asistencia")}>
                        <Card.Img variant="top" src={graphIcon} alt="Historial de asistencia"
                                  className="card-img-top card-img-profile"
                                  style={{filter: 'brightness(1) invert(1)'}}/>
                        <Card.Body>
                            <Card.Text className="card-text-profile">Historial asistencia</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

            <Row style={{width: '100%'}}>
                <Col md={6} style={{width: '48%'}}>
                    <Card className="text-center mb-4 profile-card"
                          onClick={() => handleNavClick("my-bono")}>
                        <Card.Img variant="top" src={moneyIcon} alt="Bonos y pagos"
                                  className="card-img-top card-img-profile"
                                  style={{filter: 'brightness(1) invert(1)'}}/>
                        <Card.Body>
                            <Card.Text className="card-text-profile">Bonos y pagos</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} style={{width: '48%', marginLeft: '0.5em'}}>
                    <Card className="text-center mb-4 profile-card"
                          onClick={() => console.log("Perfil habilitado")}>
                        <Card.Img variant="top" src={checkIcon} alt="check" className="card-img-top "
                                  style={{
                                      backgroundColor: '#8cc63f',
                                      height: '2rem',
                                      width: '2rem',
                                      borderRadius: '5em'
                                  }}/>
                        <Card.Body>
                            <Card.Text className="card-text-profile">Perfil Habilitado</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default ProfileOptions;
import {useEffect} from 'react';

const Login = () => {
    useEffect(() => {
        const clientId = 'nova-client';
        const redirectUri = encodeURIComponent(`${process.env.REACT_APP_URL}/callback`);
        console.log("Login -> ", redirectUri);
        document.cookie = "JSESSIONID=; Max-Age=0; path=/; secure; SameSite=None";
        const authUrl = `${process.env.REACT_APP_SECURITY_SERVER_URL}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid`;
        window.location.href = authUrl;
        // fetch(authUrl, {
        //     method: 'GET',
        //     credentials: 'omit'
        // })
    }, []);

    return null;
};

export default Login;


/*
return (
    <Container className="login-container">
        <Row className="justify-content-md-center">
            <Col md={6} style={{ backgroundColor: 'white', padding: '20px' }}>
                <Tabs
                    id="login-register-tabs"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="login" title="Login">
                        <h2 className="text-center">Login</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <div className="password-wrapper">
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span onClick={() => setShowPassword(!showPassword)} className="toggle-password">
                                            <img src={showPassword ? hidePasswordIcon : showPasswordIcon} alt="Toggle Password Visibility" />
                                        </span>
                                </div>
                            </Form.Group>
                            <Link to="/forgot-password" className="forgot-password-link">
                                ¿Has olvidado la contraseña?
                            </Link>
                            <Button variant="primary" type="submit" className="submit-btn d-block mx-auto">
                                Iniciar sesión
                            </Button>
                        </Form>
                    </Tab>
                    <Tab eventKey="register" title="Register">
                        <h2 className="text-center">Register</h2>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="submit-btn d-block mx-auto">
                                Register
                            </Button>
                        </Form>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
);*/

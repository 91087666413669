import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {cancelSession, reserveSession} from "../../services/ApiClassSessions";
import moment from "moment";

const ReservationForm = ({event, show, handleClose, cancel, myEvents}) => {
    const [initDate, setInitDate] = useState("");
    const [initTime, setInitTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [serviceName, setServiceName] = useState("");
    const [canReserve, setCanReserve] = useState(true);
    const [reserved, setReserved] = useState(false);
    const [centerName, setCenterName] = useState(false);
    const [pastEvent, setPastEvent] = useState(false);

    useEffect(() => {
        console.log("Selecteddddd event: ", event);
        console.log("Can cancel: ", cancel, reserved, !pastEvent);
        console.log("Can reserve: ", !reserved, !pastEvent, canReserve);
        setPastEvent(false);
        if (event !== null) {

            console.log("Data: ", event);
            if (moment(event.initTime).isBefore(moment().startOf('day')) || moment(event.initTime).isBefore(moment())) {
                console.log("Evento pasado");
                setPastEvent(true);
            }
            let initDateV = new Date(event.initTime);
            setInitDate(' ' + initDateV.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }));
            // setInitDate(' ' + initDateV.toISOString().split('T')[0]);
            setInitTime(' ' + initDateV.toTimeString().split(' ')[0].substring(0, 5));

            let endDate = new Date(event.endTime);
            setEndTime(' ' + endDate.toTimeString().split(' ')[0].substring(0, 5));

            setServiceName(' ' + event.service.name);
            console.log("My events: ", myEvents);
            console.log("Cancel: ", cancel);
            setReserved(myEvents.find(e => e.id === event.id) !== undefined);
            setCanReserve(new Date(event.initTime) > new Date());
            setCenterName(' ' + event.center.name);
        }
    }, [event]);

    const handleCancelReservation = async () => {
        console.log("Cancel reservation");
        let userId = localStorage.getItem('userId');
        await cancelSession(userId, event.id)
            .then(() => {
                console.log("Session cancelled");
                handleClose();
            });
    }

    const handleMakeReservation = async () => {
        console.log("Make reservation");
        let userId = localStorage.getItem('userId');
        myEvents.find(e => e.id === event.id) ? console.log("Ya reservado") :
            await reserveSession(userId, event.id)
                .then(() => {
                    console.log("Session added");
                    handleClose();
                });
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Datos de la clase</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col md={12}>
                        <Form.Label>Servicio:</Form.Label>
                        {serviceName}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Form.Label>Centro:</Form.Label>
                        {centerName}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Label style={{marginLeft: '0.2rem'}}>Día: </Form.Label>
                        {initDate}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Label>Hora: </Form.Label>
                        {initTime} - {endTime}
                    </Col>
                </Row>
                <Form>
                    {reserved && pastEvent ? (
                        <>
                            <Form.Label style={{marginLeft: '0.2rem'}}>Asistencia: </Form.Label>
                            {serviceName}
                        </>
                    ) : ""}
                    <br/>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        {/*si reserved && */}
                        {!reserved && canReserve ? (
                            // <p onClick={(e) => handleMakeReservation()}>Reservar</p>
                            <Button variant="primary" className="save-btn" type="submit"
                                    onClick={(e) => handleMakeReservation()}>
                                Reservar
                            </Button>
                        ) : ""}
                        {reserved && cancel && !pastEvent ? (
                            <Button variant="secondary" className="delete-btn" type="submit"
                                    onClick={(e) => handleCancelReservation()}>Cancelar reserva</Button>
                        ) : ""}
                    </div>

                    <br/>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ReservationForm;
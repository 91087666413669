import {Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import "../CardListStyle.css";
import React, {useEffect, useState} from "react";
import {getAllBonos} from "../../services/ApiBonos";
import {addBono, deleteBono, getPaymentMethods, updateUserPayment} from "../../services/ApiUsers";
import {FaPlusCircle, FaTrash} from "react-icons/fa";

const Payments = ({clientId, client, fetchClients}) => {

    const [selectedBonoId, setSelectedBonoId] = useState(null);
    // const [bono, setBono] = useState(null);
    const [bonos, setBonos] = useState([]);
    const [selectedBonos, setSelectedBonos] = useState([]);
    const [payments, setPayments] = useState([
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pendiente'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'}
    ]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState(null);

    useEffect(() => {
        console.log("Client----: ", client);
        setSelectedBonos(client?.bonos);
        getPaymentMethods().then((data) => {
            setPaymentMethods(data)
        });
        setPaymentMethod(client.paymentMethod?.code);
        getAllBonos({centerId: client?.center.id}).then((data) => {
            setBonos(data);
            // setBonos(data.filter(bono => !selectedBonos.some(selectedBono => selectedBono.id === bono.id)));
        }).then(() => {
            setSelectedBonoId(null)
            // console.log("bonoooo", bono)
        });
        setPaymentMethod(client?.paymentMethod?.code)
    }, []);

    useEffect(() => {
        // console.log("SelectedBonoId: ", selectedBonoId);
        // console.log("Allbonos: ", bonos);
        let asd = bonos.find(b => b.id === parseInt(selectedBonoId));

        // getAllBonos({centerId: client?.center.id}).then((data) => {
        // setBonos(bonos.filter(bono => !selectedBonos.some(selectedBono => selectedBono.id === bono.id)));
        // });

        console.log("Bono update: ", asd);
        // setBono(asd);
    }, [selectedBonoId]);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("SelectedBonoId: ", selectedBonoId);
        console.log("PaymentMethod: ", paymentMethod);
        let payment = {
            bonoId: selectedBonoId,
            paymentMethod: paymentMethod
        };
        updateUserPayment(client.id, payment).then(fetchClients());
    }

    const onAddBono = () => {
        console.log("selectedBonoId: " + selectedBonoId)
        if (selectedBonoId && selectedBonos.every(b => b.id !== parseInt(selectedBonoId))) {
            addBono(client.id, selectedBonoId).then(() => {
                setSelectedBonos([...selectedBonos, bonos.find(b => b.id === parseInt(selectedBonoId))])
            });
        }
        setSelectedBonoId(null);
    }

    const onDeleteBono = (id) => {
        deleteBono(client.id, id).then(() => {
            let filter = selectedBonos.filter(b => b.id !== id);
            setSelectedBonos(filter)
        });
    }

    return (
        <>
            <br/>
            <Row md={6} className="justify-content-md-center">
                <Col style={{width: '30%'}}>
                    <Form.Label>Bonos activos</Form.Label>
                    <Row md={6} className="justify-content-md-center">
                        {/*<Form.Group controlId="formBono" style={{width: '16rem'}}>*/}

                        <Col style={{width: '80%'}}>
                            <Form.Control as="select" value={selectedBonoId}
                                          onChange={(e) => setSelectedBonoId(e.target.value)}>
                                <option value="">Selecciona un bono</option>
                                {bonos.map(bono => (
                                    <option key={bono.id} value={bono.id}>{bono.name}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col style={{width: '20%'}}>
                            {/*<div className={`main-content ${collapsed ? "expanded" : ""}`}>*/}
                            <FaPlusCircle variant="success" alt="Add" onClick={onAddBono} style={{color: '#8cc63f'}}
                                          className={`plus-icon ${selectedBonos.some(b => b.id === parseInt(selectedBonoId))} ? "disabled" : ''`}/>
                            {/*{selectedBonos.some(b => b.id === parseInt(selectedBonoId))}/>*/}
                        </Col>
                        {/*</Form.Group>*/}
                    </Row>
                    <ListGroup as="ol" style={{maxHeight: '10rem', minHeight: '10rem', overflowY: 'auto'}}>
                        {selectedBonos.map((sBono) => (
                            <ListGroup.Item as="li" key={sBono.id} style={{padding: '0rem 0 rem', marginBottom: '0'}}>
                                <Row>
                                    <Col>
                                        {sBono.name}
                                    </Col>
                                    <Col className="text-right d-flex justify-content-end">
                                        <FaTrash variant="danger"
                                                 style={{
                                                     cursor: 'pointer',
                                                     width: '15px',
                                                     height: '15px',
                                                     color: '#dc3545'
                                                 }}
                                                 onClick={() => onDeleteBono(sBono.id)}></FaTrash>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    {/*<Row className="justify-content-md-center">
                        <Card className="card-custom text-center"
                              style={{width: '15rem', height: '12rem', minWidth: '15rem', minHeight: '10rem', marginRight: '0' }}>
                            <Card.Body>

                                <Card.Img variant="top" src={bono?.image == null ? novaIcon : bono.image}
                                          alt={'img'} className="card-img-top" style={{width: '5rem', height: '5rem'}}/>
                                <Card.Title>{bono !== null ? bono?.name : " "}</Card.Title>
                                <Card.Text>
                                    {!bono ? "El usuario no tiene ningún bono asignado." :
                                        [
                                            bono?.center?.address
                                        ]}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>*/}
                    <br/>
                    <Row className="justify-content-md-center">
                        <Form.Label>Métodos de pago</Form.Label>
                        <Form.Control as="select" value={paymentMethod}>
                            onChange={(e) => setPaymentMethod(e.target.value)}>
                            <option value="">Selecciona un método</option>
                            {paymentMethods.map(pm => (
                                <option key={pm.code} value={pm.code}>{pm.name}</option>
                            ))}
                        </Form.Control>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Card className="card-custom text-center"
                              style={{
                                  width: '15rem',
                                  height: '7rem',
                                  minWidth: '15rem',
                                  minHeight: '7rem',
                                  marginRight: '0'
                              }}>
                            <Card.Body>
                                {/*<Card.Title>{bono !== null ? bono?.name : " "}</Card.Title>*/}
                                <Card.Text>
                                    Cuenta bancaria: <br/>
                                    **** **** **** **** **** 1234
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col style={{width: '70%'}}>
                    <Row>
                        <Form.Group controlId="formPayments">
                            <Form.Label>Historial de pagos</Form.Label>

                            <ListGroup as="ol" style={{maxHeight: '25rem', overflowY: 'auto'}}>
                                {payments.map((payment) => (

                                    <ListGroup.Item as="li" key={payment.id}>
                                        <Row md={3} className="justify-content-md-center">
                                            <Col style={{width: '40%'}}>
                                                {payment.bono}
                                            </Col>
                                            <Col style={{width: '20%'}}>
                                                {payment.method}
                                            </Col>
                                            <Col style={{width: '20%'}}>
                                                {payment.date}
                                            </Col>

                                            <Col style={{
                                                width: '20%',
                                                color: payment.status === 'Pagado' ? 'green' : payment.status === 'Pendiente' ? 'orange' : 'black'
                                            }}>
                                                {payment.status}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            {/*            <Button variant="primary" className="save-btn float-xl-end" type="submit"
                    style={{position: 'absolute', bottom: '2rem', right: '2rem'}}
                    onClick={e => onSubmit(e)}>
                Guardar
            </Button>*/}
        </>
    )
}
export default Payments;
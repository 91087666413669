import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import './CenterList.css';
import CenterForm from "./CenterForm";
import plusIcon from "../../assets/añadir.svg";
import {getAllCenters} from "../../services/ApiCenters";
import novaIcon from "../../assets/favicon.svg";

const CenterList = () => {

    const [centers, setCenters] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('create');
    const [menuVisible, setMenuVisible] = useState([]);

    const getCenters = async () => {
        try {
            getAllCenters().then((data) => {
                setMode('create');
                setSelectedCenter(null)
                setMenuVisible(data);
            });
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    }

    useEffect(() => {
        getCenters();
    }, []);


    const handleClose = () => {
        setShow(false);
    }

    const handleAddClick = () => {
        setMode('create');
        setShow(true);
    }

    const handleEditClick = (center) => {
        setSelectedCenter(center);
        setMode('edit');
        setShow(true);
    }

    return (
        <>
            <div id="addNew" className="text-right" style={{position: 'relative', right: '5%'}}>
                <img src={plusIcon} alt="Add" className="plus-icon" onClick={handleAddClick}
                     style={{width: '30px', height: '30px', position: 'absolute', right: '0'}}/>
            </div>
            <br/>
            <br/>
            <Row className="center-list-row">
                {/*<Row style={{ display: 'flex', flexWrap: 'wrap' }}>*/}
                {menuVisible && menuVisible.map((center) => (
                    <Col key={center.id} xs={12} sm={6} md={4} lg={3}
                         className="text-right d-flex justify-content-center">
                        {/*<Col key={center.id} xs={2} sm={2} md={2} lg={2} xl={2}>*/}
                        <Card className="text-center mb-4 card-custom">
                            {/*<div className="icon-container">*/}
                            {/*    <FaTrash variant="danger" disabled={!service.removable}*/}
                            {/*             style={{*/}
                            {/*                 cursor: center.removable ? 'pointer' : 'not-allowed',*/}
                            {/*                 width: '15px',*/}
                            {/*                 height: '15px',*/}
                            {/*                 color: service.removable ? '#dc3545' : 'gray',*/}
                            {/*             }}*/}
                            {/*             onClick={() => onDeleteClick(service)}/>*/}
                            {/*</div>*/}

                            <Card.Img onClick={() => handleEditClick(center)}
                                      variant="top"
                                      src={center.image == null ? novaIcon : center.image} // Use the imported image
                                      alt={center.name}
                                      className="card-img-top"
                            />
                            {/*<Card.Img
                                variant="top"
                                src={`data:image/jpeg;base64,${center.image}`}
                                // src={`data:image/jpeg;base64,${arrayBufferToBase64(center.image)}`}
                                alt={center.name}
                            />*/}
                            <Card.Body onClick={() => handleEditClick(center)}>
                                <Card.Title>{center.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{center.email}<br/>{center.tlf}
                                </Card.Subtitle>
                                <Card.Text>
                                    {center.address}
                                </Card.Text>
                            </Card.Body>
                            {/*{menuVisible && selectedCenter === center && (
                            <div className="menu">
                                <ul>
                                    <li>Option 1</li>
                                    <li>Option 2</li>
                                    <li>Option 3</li>
                                </ul>
                            </div>
                        )}*/}
                        </Card>
                    </Col>
                ))}
            </Row>
            <CenterForm show={show} center={selectedCenter} onClose={handleClose} mode={mode}
                        fetchCenters={getCenters}/>
        </>
    )
};

export default CenterList;


import React, {useEffect, useState} from "react";
import '../../FormStyle.css';
import './BonoForm.css'
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import novaIcon from "../../../assets/favicon.svg";
import {createBono, updateBono} from "../../../services/ApiBonos";
import {getAllServices} from "../../../services/ApiServices";


const BonoForm = ({show, handleClose, mode, data, centers, selectedCenter, onBonoChange}) => {

    const [types] = useState([
        {name: 'Diario', code: 'DAILY'}, {name: 'Semanal', code: 'WEEKLY'}, {name: 'Mensual', code: 'MONTHLY'}]);
    const [allDays] = useState([
        {name: 'Lunes', code: 'MONDAY'}, {name: 'Martes', code: 'TUESDAY'}, {name: 'Miércoles', code: 'WEDNESDAY'},
        {name: 'Jueves', code: 'THURSDAY'}, {name: 'Viernes', code: 'FRIDAY'}, {name: 'Sábado', code: 'SATURDAY'},
        {name: 'Domingo', code: 'SUNDAY'}]);

    const [selectedCenters, setSelectedCenters] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [type, setType] = useState("MONTHLY");
    const [name, setName] = useState("");
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(data?.image || novaIcon);
    const [price, setPrice] = useState(0);
    const [available, setAvailable] = useState(true);
    const [weekDays, setWeekDays] = useState([]);
    const [amount, setAmount] = useState(1);
    const [availableServices, setAvailableServices] = useState([]);


    const onClose = () => {
        setName("");
        setSelectedServices([]);
        setImage(null)
        setSelectedCenters([]);
        setPreview(null);
        setType("MONTHLY");
        setAvailable(true);
        setWeekDays([]);
        setPrice(0);
        setAmount(1);
        setAvailableServices([]);
        handleClose();
    }

    useEffect(() => {

        if (mode === "edit" && data) {
            setName(data.name);
            setSelectedServices(data.services);
            setSelectedCenters(data.centers ? data.centers : []);
            setImage(data.image)
            setPreview(data.image);
            setType(data.type);
            setAmount(data.amount);
            setAvailable(data.available);
            setWeekDays(data.weekDays ? data.weekDays : []);
            setPrice(data.price);
            setAvailableServices([]);
        } else {
            setName("");
            setSelectedServices([]);
            setImage(null)
            setSelectedCenters([]);
            setPreview(null);
            setType("MONTHLY");
            setAvailable(true);
            setWeekDays([]);
            setPrice(0);
            setAmount(1);
            setAvailableServices([]);
        }
    }, [mode, data]);

    useEffect(() => {
        let ids = selectedCenters.map(sc => sc.id);
        console.log("asdasdasd: ", ids)
        if (ids.length > 0) {
            getAllServices({
                available: true, centerIds: selectedCenters.map(sc => sc.id)
            }).then((data) => {
                console.log("Services: ", data);
                setAvailableServices(data);
            });
        }
    }, [selectedCenters])

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);

        loadImagePreview(file);
    };

    const loadImagePreview = (image) => {
        // Vista previa de la imagen
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (image) {
            reader.readAsDataURL(image);
        }
        return reader.result;
    }

    const addSelectedService = (service) => {
        let services = [...selectedServices];
        if (services.find(s => s.id === service.id)) {
            services = services.filter(s => s.id !== service.id);
        } else {
            services.push(service);
        }
        setSelectedServices(services);
    }

    const addSelectedCenter = (center) => {
        console.log(center)
        let centers = [...selectedCenters];
        if (centers.find(s => s.id === center?.id)) {
            centers = centers.filter(s => s.id !== center.id);
        } else {
            centers.push(center);
        }
        setSelectedCenters(centers);
    }

    const addSelectedDay = (day) => {
        let days = [...weekDays];
        if (days.find(d => d.code === day.code)) {
            days = days.filter(d => d !== day);
        } else {
            days.push(day);
        }
        setWeekDays(days);
    }

    useEffect(() => {
        if (type === 'DAILY') {
            setWeekDays([]);
        }
    }, [type]);

    const handleSubmit = (e) => {

        e.preventDefault();

        const dataForm = {
            centers: selectedCenters,
            name,
            services: selectedServices,
            weekDays,
            price,
            type,
            amount,
            available,
            image: preview
        };

        if (mode === "edit" && data) {
            updateBono(data.id, dataForm).then(() => onBonoChange(selectedCenter.id));
        } else {
            createBono(dataForm).then(() => onBonoChange(selectedCenter.id));
        }

        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="form-modal" size={"lg"} centered>
            <Modal.Header closeButton style={{border: "none", padding: "0.5rem"}}>

            </Modal.Header>
            <Modal.Body className="modal-body-scrollable">
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="formImagen">
                                <Form.Label>Imagen del Curso</Form.Label>
                                <div className="mt-3 image-div">
                                    <img src={image ? preview : novaIcon} alt="Vista previa" style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '50%',
                                        objectFit: 'cover'
                                    }}/>
                                </div>
                                <Form.Control className={"sm"}
                                              type="file"
                                              onChange={(e => handleFileChange(e))}
                                              accept="image/*"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder="Ingresa el nombre" value={name}
                                              onChange={(e) => setName(e.target.value)} required/>
                            </Form.Group>
                            <Form.Group controlId="formCenter">
                                <Form.Label>Centros</Form.Label>
                                <div className="list-container">
                                    {centers.slice(0).map((center) => (
                                        <div
                                            key={center.id}
                                            onClick={() => addSelectedCenter(center)}
                                            className={`item ${selectedCenters?.find(c => c?.id === center?.id) !== undefined ? 'selected' : ''}`}
                                        >
                                            {center.name}
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="formServices">
                                <Form.Label>Servicios a los que aplicar el bono</Form.Label>
                                <div className="list-container">
                                    {availableServices.slice(0).map((service) => (
                                        <div
                                            key={service.id}
                                            onClick={() => addSelectedService(service)}
                                            className={`item ${selectedServices.find(s => s.id === service.id) !== undefined ? 'selected' : ''}`}
                                        >
                                            {service.name}
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <Form.Group controlId="formType">
                                <Form.Label>Periodo de tiempo al que aplicar el bono</Form.Label>
                                <div className="list-container">
                                    {types.slice(0).map((itype) => (
                                        <div
                                            key={itype.code}
                                            onClick={() => setType(itype.code)}
                                            className={`item ${itype.code === type ? 'selected' : ''}`}
                                        >
                                            {itype.name}
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="formAmount">
                                <Form.Label>Cantidad</Form.Label>
                                <Form.Control type="number" placeholder="1" value={amount}
                                              onChange={(e) => setAmount(e.target.value)} required>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <Form.Group controlId="formPrice">
                                <Form.Label>Precio de venta</Form.Label>
                                <Form.Control type="number" placeholder="€" value={price}
                                              onChange={(e) => setPrice(e.target.value)} required>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="formAvailable">
                                <Form.Label>Mostrar en la App</Form.Label>
                                <Form.Check
                                    className={`custom-switch ${available ? 'checked' : 'unchecked'} shadow-none`}
                                    type="switch"
                                    label={available ? "Si" : "No"}
                                    checked={available}
                                    onChange={(e) => setAvailable(e.target.checked)} required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/*{type !== 'DAILY' && (*/}
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="formDays">
                                <Form.Label>¿Qué días podrá ir el cliente?</Form.Label>
                                <div className="list-container">
                                    {allDays.slice(0).map((day) => (
                                        <div
                                            key={day.code}
                                            onClick={() => addSelectedDay(day)}
                                            className={`item ${weekDays.find(d => d.code === day.code) !== undefined ? 'selected' : ''}`}
                                            style={{
                                                pointerEvents: type === 'DAILY' ? 'none' : 'auto',
                                                opacity: type === 'DAILY' ? 0.5 : 1
                                            }}
                                        >
                                            {day.name}
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/*)}*/}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="cancel-btn" onClick={onClose}>
                    Cancelar
                </Button>
                <Button variant="primary" className="save-btn" type="submit" onClick={e => handleSubmit(e)}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BonoForm;
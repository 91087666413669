import './Menu.css';
import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import wlogo from "../../assets/LOGO_NOVA_WHITE-34.svg";
import icon from "../../assets/favicon.svg";
import {useNavigate} from "react-router-dom";
import centersLogo from "../../assets/centros.svg";
import servicesLogo from "../../assets/servicios.svg";
import calendarLogo from "../../assets/calendario-lineas.svg";
import trainersLogo from "../../assets/entrenadores.svg";
import clientsLogo from "../../assets/clientes-lineas.svg";
import statisticsLogo from "../../assets/estadisticas.svg";
import billingLogo from "../../assets/dinero-lineas.svg";
import userLogo from "../../assets/usuario.svg";
import backgroundImg from "../../assets/Fondo.svg";

export const Menu = ({collapsed, setCollapsed, selectedPage, setSelectedPage, setSelectedPageTitle}) => {

    const navigate = useNavigate();

    const handleNavClick = (page, route, title) => {
        setSelectedPage(page);
        setSelectedPageTitle(title);
        navigate(route);
    };

    const hasRole = (role) => {
        const roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : [];
        return roles.includes(role);
    };

    return (
        <div className="d-flex">
            {/* Sidebar for large screens */}
            <div className={`sidebar ${collapsed ? "collapsed" : ""} d-none d-md-block`}>
                <div className="logo-div d-flex align-items-center justify-content-around"
                     onClick={() => handleNavClick("Dashboard", "/dashboard", "Dashboard")}>
                    <img src={`${collapsed ? icon : wlogo}`} alt="Logo" className="logo"/>
                </div>
                <button
                    className="toggle-btn btn btn-outline-secondary my-3"
                    onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? <BsChevronRight/> : <BsChevronLeft/>}
                </button>

                <Nav className={`flex-column ${collapsed ? "flex-row" : ""}`}>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Calendar" ? "active" : ""}
                                  onClick={() => handleNavClick("Calendar", "/calendar", "Calendario")}>
                            <img src={calendarLogo} alt="Calendario" className="menu-icon"/> {!collapsed &&
                            <span>Calendario</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Centers" ? "active" : ""}
                                  onClick={() => handleNavClick("Centers", "/centers", "Centros")}>
                            <img src={centersLogo} alt="Centros" className="menu-icon"/> {!collapsed &&
                            <span>Centros</span>}
                        </Nav.Link>
                    </Nav.Item>
                    {hasRole("USER") && (
                        <Nav.Item>
                            <Nav.Link className={selectedPage === "Schedule" ? "active" : ""}
                                      onClick={() => handleNavClick("Schedule", "/calendar", "Horarios")}>
                                {/*onClick={() => handleNavClick("Calendar", "/calendar", hasRole("ADMIN") ? "Calendario" : "Mis clasess"))}>*/}
                                <img src={calendarLogo} alt="Calendario" className="menu-icon"/>
                                <span>Horarios</span>
                                {/*<span>{hasRole("ADMIN") ? "Clandario" : "Mis Clases"}</span>*/}
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {hasRole("USER") && (
                        <Nav.Item>
                            <Nav.Link className={selectedPage === "Profile" ? "active" : ""}
                                      onClick={() => handleNavClick("Profile", "/profile-menu", "Servicios")}>
                                <img src={userLogo} alt="ProfileOptions" className="menu-icon"
                                     style={{filter: 'brightness(0) invert(1)'}}/> {!collapsed &&
                                <span>Perfil</span>}
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {hasRole("ADMIN") && (
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Services" ? "active" : ""}
                                  onClick={() => handleNavClick("Services", "/services", "Servicios")}>
                            <img src={servicesLogo} alt="Centros" className="menu-icon"/> {!collapsed &&
                            <span>Servicios</span>}
                        </Nav.Link>
                    </Nav.Item>
                    )}
                    {hasRole("ADMIN") && (
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Trainers" ? "active" : ""}
                                  onClick={() => handleNavClick("Trainers", "/trainers", "Entrenadores")}>
                            <img src={trainersLogo} alt="Entrenadores" className="menu-icon"/> {!collapsed &&
                            <span>Entrenadores</span>}
                        </Nav.Link>
                    </Nav.Item>
                    )}
                    {hasRole("ADMIN") && (
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Clients" ? "active" : ""}
                                  onClick={() => handleNavClick("Clients", "/clients", "Clientes")}>
                            <img src={clientsLogo} alt="Clients" className="menu-icon"/> {!collapsed &&
                            <span>Clientes</span>}
                        </Nav.Link>
                    </Nav.Item>
                    )}
                    {hasRole("ADMIN") && (
                        <Nav.Item>
                            <Nav.Link className={selectedPage === "Statistics" ? "active" : ""}
                                      onClick={() => handleNavClick("Statistics", "/statistics", "Estadísticas")}>
                                <img src={statisticsLogo} alt="Estadísticas" className="menu-icon"/> {!collapsed &&
                                <span>Estadísticas</span>}
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {hasRole("ADMIN") && (
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Billing" ? "active" : ""}
                                  onClick={() => handleNavClick("Billing", "/billing", "Facturación")}>
                            <img src={billingLogo} alt="Facturación" className="menu-icon"/> {!collapsed &&
                            <span>Facturación</span>}
                        </Nav.Link>
                    </Nav.Item>
                    )}
                </Nav>
            </div>

            {/* Bottom navbar for small screens */}

            <Navbar className="d-block d-md-none fixed-bottom bg-light"
                    style={{
                        paddingBottom: '0px',
                        paddingTop: '0px',
                        backgroundImage: `url(https://i.blogs.es/0ca9a6/aa/1366_2000.jpeg)`
                    }}>

                <Nav className="w-100 d-flex justify-content-around bottom-navbar" style={{
                    paddingBottom: '0px',
                    paddingTop: '0px',
                    backgroundImage: `url(${backgroundImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left bottom'
                }}>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Centers" ? "active" : ""}
                                  onClick={() => handleNavClick("Centros", "/centers", "Centros")}>
                            <img src={centersLogo} alt="Centros" className="menu-icon"/>
                            <span>Centros</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Calendar" ? "active" : ""}
                                  onClick={() => handleNavClick("Calendar", "/calendar", "Horarios")}>
                            {/*onClick={() => handleNavClick("Calendar", "/calendar", hasRole("ADMIN") ? "Calendario" : "Mis clasess"))}>*/}
                            <img src={calendarLogo} alt="Calendario" className="menu-icon"/>
                            <span>Horarios</span>
                            {/*<span>{hasRole("ADMIN") ? "Clandario" : "Mis Clases"}</span>*/}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Calendar" ? "active" : ""}
                                  onClick={() => handleNavClick("Calendar", "/my-calendar", "Mis clases")}>
                            {/*onClick={() => handleNavClick("Calendar", "/calendar", hasRole("ADMIN") ? "Calendario" : "Mis clasess"))}>*/}
                            <img src={calendarLogo} alt="Calendario" className="menu-icon"/>
                            <span>Mis Clases</span>
                            {/*<span>{hasRole("ADMIN") ? "Clandario" : "Mis Clases"}</span>*/}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={selectedPage === "Profile" ? "active" : ""}
                                  onClick={() => handleNavClick("Profile", "/profile-menu", "Perfil")}>
                            <img src={userLogo} alt="ProfileOpt" className="menu-icon profile-icon"
                                 style={{filter: 'brightness(0) invert(1)'}}/>
                            <span>Perfil</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar>
        </div>
    );
};

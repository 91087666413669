const apiFetch = async (url, options = {}) => {
    const accessToken = localStorage.getItem('access_token');
    const updatedDate = localStorage.getItem('token_updated');
    const expiresIn = localStorage.getItem('expires_in');
    // console.log("API FETCH START -> ", url);
    // console.log("updated date -> ", updatedDate);
    // console.log("ExpiresAt -> ", expiresIn);
    let differenceInSeconds = 0;
    if (updatedDate) {
        // Convertirla en número
        const storedTimestamp = parseInt(updatedDate, 10);

        // Obtener el tiempo actual en milisegundos
        const currentTimestamp = Date.now();

        // Calcular la diferencia en segundos
        differenceInSeconds = (currentTimestamp - storedTimestamp) / 1000;

        // Comprobar si han pasado más de 300 segundos
        if (differenceInSeconds > 300) {
            console.log('300');
        } else {
            console.log(`Han pasado ${Math.floor(differenceInSeconds)} segundos`);
        }
    } else {
        // console.log('No se encontró la fecha en localStorage');
    }

    if (accessToken === null || differenceInSeconds > parseInt(expiresIn)) {
        window.location.href = '/login';
    }
    const headers = {
        ...options.headers,
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };

    const response = await fetch(url, {
        ...options,
        headers,
    });

    // console.log("API FETCH END -> ", response);
    // console.log("API FETCH END -> ", response.status);
    if (response.status === 401) {
        console.log("401");
        window.location.href = '/login';

    }
    if (!response.ok) {
        throw new Error('Network response was not ok', response);
    }

    return response;
};

export default apiFetch;
import React, {useEffect, useState} from "react";
import {Col, Form, ListGroup, Row} from "react-bootstrap";
import {FaPlusCircle, FaTrash} from "react-icons/fa";
import plusIcon from "../../assets/añadir.svg";
import checkIcon from "../../assets/check.svg";
import {addGoal, addInjury, checkGoal, deleteGoal, deleteInjury, getInjuryPlaces} from "../../services/ApiUsers";

const Goals = ({client, mode, centers, fetchClients}) => {
    const [clientId, setClientId] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [goals, setGoals] = useState([]);
    const [newGoalDate, setNewGoalDate] = useState("");
    const [newGoalDesc, setNewGoalDesc] = useState("");
    const [injuries, setInjuries] = useState([]);
    const [newInjuryDate, setNewInjuryDate] = useState("");
    const [newInjuryDesc, setNewInjuryDesc] = useState("");
    const [newInjuryPlace, setNewInjuryPlace] = useState("");
    const [injuryPlaces, setInjuryPlaces] = useState([]);

    useEffect(() => {
        console.log("Client: ", client);
        getInjuryPlaces().then(data => setInjuryPlaces(data));
        if (mode === "edit" && client) {
            setClientId(client.id);
            setName(client.name || "");
            setSurname(client.surname || "");
            setGoals(client.goals || [{'id': 1, 'date': new Date().toString(), 'description': 'Objetivo 1'}, {
                'id': 2,
                'date': new Date().toString(),
                'description': 'Objetivo 2'
            }])
            setInjuries(client.injuries || [{
                'id': 1,
                'date': new Date().toString(),
                'description': 'Lesión 1',
                'place': 'Hombro'
            }]);
        } else {
            setClientId("")
            setName("");
            setSurname("");
            setGoals([])
        }
    }, [mode, client]);

    const handleAddGoal = () => {
        const newGoal = {
            date: newGoalDate,        // Puedes cambiar esto por un input de fecha
            description: newGoalDesc  // Lo mismo con la descripción
        };
        setGoals([...goals, newGoal].sort((a, b) => new Date(a.date) - new Date(b.date)));  // Agrega el nuevo objetivo y ordena por fecha
        addGoal(clientId, newGoal);
        setNewGoalDesc("");
        setNewGoalDate("");
        console.log("Goals: ", goals);
    };

    // Función para eliminar un objetivo
    const handleCheckClick = (id) => {
        let status = false;
        const newGoals = goals.map(obj => {
            if (obj.id === id) {
                obj.checked = !obj.checked;
                status = obj.checked;
            }
            return obj;
        });
        setGoals(newGoals);
        checkGoal(id, status);
        console.log("Goal check: ", id);
    }

    const handleDeleteGoal = (id) => {
        const newGoals = goals.filter(obj => obj.id !== id); // Filtra el objetivo a eliminar
        setGoals(newGoals);
        deleteGoal(id);
    };

    const handleAddInjury = () => {
        let place = injuryPlaces.find(ip => ip.code === newInjuryPlace);
        const newInjury = {
            date: newInjuryDate,        // Puedes cambiar esto por un input de fecha
            description: newInjuryDesc,  // Lo mismo con la descripción
            place: place
        };
        setInjuries([...injuries, newInjury].sort((a, b) => new Date(a.date) - new Date(b.date)));  // Agrega el nuevo objetivo y ordena por fecha
        addInjury(clientId, newInjury);
        setNewInjuryDesc("");
        setNewInjuryDate("");
        setNewInjuryPlace("");
    };

    // Función para eliminar un objetivo
    const handleDeleteInjury = (id) => {
        const newInjuries = injuries.filter(obj => obj.id !== id); // Filtra el objetivo a eliminar
        setInjuries(newInjuries);
        deleteInjury(id);
    };

    return (
        <>
            <Row style={{marginTop: '2rem'}}>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Objetivos</Form.Label>
                        <Row style={{width: '100%'}}>
                            <Col md={4} style={{width: '35%'}}>
                                <Form.Control as="input" type="date" value={newGoalDate}
                                              onChange={(e) => setNewGoalDate(e.target.value)}/>
                            </Col>
                            <Col md={4} style={{width: '60%'}}>
                                <Form.Control as="input" type="text" value={newGoalDesc}
                                              placeholder={"Descripción del objetivo"}
                                              onChange={(e) => setNewGoalDesc(e.target.value)}/>
                            </Col>
                            <Col md={4} style={{width: '5%'}}>
                                {/*<Button className="save-btn">Añadir</Button>*/}
                                <FaPlusCircle variant="success" alt="Add" className="plus-icon"
                                              onClick={handleAddGoal} style={{color: '#8cc63f'}}/>
                                {/*<img src={plusIcon} alt="Add" className="plus-icon" onClick={handleAddGoal}/>*/}
                            </Col>
                        </Row>
                        <ListGroup as="ol">
                            {goals.map((goal) => (

                                <ListGroup.Item as="li" key={goal.id}
                                                style={{padding: '0rem 0 rem', marginBottom: '0'}}>
                                    <Row md={4}>
                                        <Col style={{width: '80%'}}>
                                            {goal.date} - {goal.description}
                                        </Col>
                                        <Col className="text-right d-flex justify-content-end" style={{width: '10%'}}>
                                            <img src={goal.checked ? checkIcon : plusIcon} variant="danger"
                                                 style={{
                                                     cursor: 'pointer',
                                                     width: '1.2rem',
                                                     height: '1.2rem',
                                                     color: '#dc3545'
                                                 }}
                                                 onClick={() => handleCheckClick(goal.id)}></img>
                                        </Col>
                                        <Col className="text-right d-flex justify-content-end" style={{width: '10%'}}>
                                            <FaTrash variant="danger"
                                                     style={{
                                                         cursor: 'pointer',
                                                         width: '15px',
                                                         height: '15px',
                                                         color: '#dc3545'
                                                     }}
                                                     onClick={() => handleDeleteGoal(goal.id)}></FaTrash>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Historial médico / lesiones</Form.Label>
                        <Row style={{width: '100%'}} md={6}>
                            <Col md={4} style={{width: '35%'}}>
                                <Form.Control as="input" type="date" value={newInjuryDate}
                                              onChange={(e) => setNewInjuryDate(e.target.value)}/>
                            </Col>
                            <Col md={4} style={{width: '65%'}}>

                                <Form.Control as="select" value={newInjuryPlace}
                                              onChange={(e) => setNewInjuryPlace(e.target.value)}>
                                    <option value="">Ubicación de la lesión (opcional)</option>
                                    {injuryPlaces?.map(ip => (
                                        <option key={ip.code} value={ip.code}>{ip.name}</option>
                                    ))}
                                </Form.Control>

                            </Col>
                        </Row>
                        <Row style={{width: '100%'}} md={6}>
                            <Col md={4} style={{width: '90%'}}>
                                <Form.Control as="input" type="text" value={newInjuryDesc}
                                              placeholder={"Descripción de la lesión"}
                                              onChange={(e) => setNewInjuryDesc(e.target.value)}/>
                            </Col>
                            <Col md={4} style={{width: '5%'}}>
                                <FaPlusCircle variant="success" alt="Add" className="plus-icon"
                                              onClick={handleAddInjury} style={{color: '#8cc63f'}}/>
                                {/*<img src={plusIcon} alt="Add" className="plus-icon" onClick={handleAddGoal}/>*/}
                            </Col>
                        </Row>

                        <ListGroup as="ol">
                            {injuries.map((injury) => (

                                <ListGroup.Item as="li" key={injury.id}
                                                style={{padding: '0rem 0 rem', marginBottom: '0'}}>
                                    <Row>
                                        <Col>
                                            {injury.date} - {injury.description} {injury.place ? `(${injury.place.name})` : ""}
                                        </Col>
                                        <Col className="text-right d-flex justify-content-end">
                                            <FaTrash variant="danger"
                                                     style={{
                                                         cursor: 'pointer',
                                                         width: '15px',
                                                         height: '15px',
                                                         color: '#dc3545'
                                                     }}
                                                     onClick={() => handleDeleteInjury(injury.id)}></FaTrash>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}

export default Goals;
import {Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import ProfileAdmin from "./ProfileAdmin";
import Enabled from "./Enabled";
import Payments from "./Payments";
import AttendanceHistory from "./AttendanceHistory";
import Goals from "./Goals";
import './Client.css';
import {FaCircleXmark} from "react-icons/fa6";


const ClientModal = ({show, client, onClose, mode, centers, fetchClients}) => {
    const [page, setPage] = useState("profile");
    const [activeTab, setActiveTab] = useState('perfil');

    const setEnabled = (enabled) => {
        client.available = enabled;
    }

    const handleOnClose = () => {
        setActiveTab("perfil");
        onClose();
    }

    return (
        <Modal show={show} onHide={onClose} dialogClassName="form-modal" size={'xl'} centered backdrop="static"
               keyboard={false} contentClassName={"modal-content-client"}>
            {/*<Modal show={show} onHide={onClose} dialogClassName="form-modal" size={page === 'goals' || page === 'attendanceHistory' ? 'lg' : 'xl'} centered backdrop="static" keyboard={false}>*/}

            <Modal.Body className="modal-body-scrollable" style={{minHeight: '600px'}}>
                <div className="custom-tabs nav nav-tabs">
                    {/* Header for the Tabs */}
                    <div className="tab-headers">
                        <div
                            className={`nav-item tab-header ${activeTab === 'perfil' ? 'active' : ''}`}
                            onClick={() => setActiveTab('perfil')}
                        >
                            Perfil
                        </div>
                        <div
                            className={`nav-item tab-header ${activeTab === 'goals' ? 'active' : ''}`}
                            onClick={() => setActiveTab('goals')}
                        >
                            Objetivos y lesiones
                        </div>
                        <div
                            className={`nav-item tab-header ${activeTab === 'attendanceHistory' ? 'active' : ''}`}
                            onClick={() => setActiveTab('attendanceHistory')}
                        >
                            Historial de asistencias
                        </div>
                        <div
                            className={`nav-item tab-header ${activeTab === 'payments' ? 'active' : ''}`}
                            onClick={() => setActiveTab('payments')}
                        >
                            Bonos y Pagos
                        </div>
                        <div
                            className={`nav-item tab-header ${activeTab === 'enabled' ? 'active' : ''}`}
                            onClick={() => setActiveTab('enabled')}>
                            Habilitar/deshabilitar
                        </div>

                    </div>

                    {/* Content for each Tab */}
                    <div className="tab-content">
                        <FaCircleXmark style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1.2rem',
                            cursor: 'pointer',
                            width: '2rem',
                            height: '2rem'
                        }}
                                       onClick={() => handleOnClose()}/>

                        {activeTab === 'perfil' && (
                            <Form>
                                <ProfileAdmin client={client} mode={mode} centers={centers}
                                              fetchClients={fetchClients}/>
                            </Form>
                        )}
                        {activeTab === 'goals' && (
                            <Form>
                                <Goals client={client} mode={mode} centers={centers} fetchClients={fetchClients}/>
                            </Form>
                        )}
                        {activeTab === 'attendanceHistory' && (
                            <Form>
                                <AttendanceHistory clientId={client?.id}/>
                            </Form>
                        )}
                        {activeTab === 'payments' && (
                            <Payments client={client?.id} client={client} fetchClients={fetchClients}/>
                        )}
                        {activeTab === 'enabled' && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Form>
                                    <Enabled clientId={client?.id} enabled={client?.available} setEnabled={setEnabled}
                                             fetchClients={fetchClients}/>
                                </Form>
                            </div>
                        )}
                    </div>
                </div>


            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*    <Button variant="secondary" className="cancel-btn" onClick={onClose}>*/}
            {/*        Cancelar*/}
            {/*    </Button>*/}

            {/*</Modal.Footer>*/}
        </Modal>
    );
}

export default ClientModal;

import {jwtDecode} from "jwt-decode";
import {getUserById} from "../services/ApiUsers";
import {useEffect, useRef} from "react";


async function getAccessToken(code) {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_PUBLIC_URL}/oauth2/token`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code
            })
        });
        if (!response.ok) {
            console.error('Network response was not ok');
        }
        const data = await response.json();

        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('expires_in', data.expires_in);

        const decodedToken = jwtDecode(data.access_token);
        let roles = decodedToken.roles;
        let value = Array.isArray(roles) ? roles : roles.split(',');
        let value1 = JSON.stringify(value);

        localStorage.setItem('roles', value1);
        localStorage.setItem('name', decodedToken.name);
        localStorage.setItem('surname', decodedToken.surname);
        localStorage.setItem('username', decodedToken.username);
        localStorage.setItem('userId', decodedToken.user_id);
        localStorage.setItem('centerId', decodedToken.center_id);

        // RefeshChrono();

        let userById = getUserById(decodedToken.user_id);
        userById
            .then((data) => {
                console.log(data)
                localStorage.setItem('center', data.center);
                localStorage.setItem('user', data);
            })
            .catch((error) => console.log('Error fetching user', error));

        return data;
    } catch (error) {
        console.error('--AuthService: Error fetching access token:', error);
    }
};


async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    console.log('Refrescando token...', refreshToken);
    if (refreshToken !== null) {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_PUBLIC_URL}/oauth2/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'grant_type': 'refresh_token',
                    'refresh_token': refreshToken
                })
            });
            if (response.ok) {
                const {accessToken, refreshToken: newRefreshToken, expiresIn} = await response.json();
                console.log(response)

                // Almacenar el nuevo access token y, si está presente, también el nuevo refresh token
                localStorage.setItem('access_token', accessToken);
                if (newRefreshToken) {
                    localStorage.setItem('refresh_token', newRefreshToken);
                }
                localStorage.setItem('expires_in', expiresIn);

                RefeshChrono();
            } else {
                console.error('Error: no se pudo refrescar el token');
            }
        } catch (error) {
            console.error('Error al refrescar el token', error);
        }
    } else {
        console.log('No hay token de refresco almacenado');
    }
};

const RefeshChrono = () => {

    const lastActivityRef = useRef(Date.now());
    const updateActivity = () => {
        lastActivityRef.current = Date.now();
    };

    function interval() {
        // const call = (lastActivityRef) => {
        // const lastActivityRef = useRef(Date.now());
        console.log('Interval first')
        window.addEventListener('mousemove', updateActivity());
        window.addEventListener('keydown', updateActivity());
        const fiveMinutes = 4 * 60 * 1000;
        setInterval(() => {
            const now = Date.now();
            console.log('Execution interval');
            console.log('Now:', now);
            console.log('Last activity:', lastActivityRef);
            if (now - lastActivityRef.current < fiveMinutes) {
                console.log('Refrescando token...');
                refreshAccessToken();
            } else {
                console.warn('Token ha caducado debido a inactividad');
                window.location.href = '/login';
            }
        }, 4 * 60 * 1000);
        // }
    }

    useEffect(() => {
        interval();
    }, []);
}


export {getAccessToken};
// export {getAccessToken, refreshAccessToken, interval, tokenRefresher};
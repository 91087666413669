import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config';
import UpdateModal from "./UpdateModal";


const root = ReactDOM.createRoot(document.getElementById('root'));
console.log('App Config:', config);
const apiUrl = process.env.REACT_APP_SERVER_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;

console.log('API URL:', apiUrl);
console.log('Environment:', environment);

const Main = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleUpdate = () => {
        setShowModal(false);
        window.location.reload();
    };

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/service-worker.js').then((registration) => {
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller) {
                                console.log('New content is available; please refresh.');
                                setShowModal(true);
                            } else {
                                console.log('Content is cached for offline use.');
                            }
                        }
                    };
                };
            }).catch((error) => {
                console.log('ServiceWorker registration failed: ', error);
            });
        });
    }

    return (
        <>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
            <UpdateModal show={showModal} handleClose={handleClose} handleUpdate={handleUpdate}/>
        </>
    );
};

root.render(
    <React.StrictMode>
        <Main/>
    </React.StrictMode>
);

reportWebVitals();
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FaUserCircle} from "react-icons/fa";
import {getUserById, updateUser} from "../../services/ApiUsers";

const ProfileUser = ({}) => {
    const [clientId, setClientId] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [birthday, setBirthday] = useState("");
    const [address, setAddress] = useState("");
    const [tlf, setTlf] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [email, setEmail] = useState("");
    const [center, setCenter] = useState("");

    useEffect(() => {
        let client = getUserById(localStorage.getItem('userId')).then((client) => {
            setClientId(client.id);
            setName(client.name || "");
            setSurname(client.surname || "");
            setBirthday(client.birthDate || "");
            setAddress(client.address || "");
            setTlf(client.tlf || "");
            setIdNumber(client.dni || "");
            setEmail(client.email || "");
            setCenter(client.center?.id || "");
            setAddress(client.address || "");
        });
        console.log("Client: ", client);
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("Submit profile: " + e);
        let client = {
            id: clientId,
            name: name,
            surname: surname,
            birthDate: birthday,
            address: address,
            tlf: tlf,
            email: email
        };
        console.log("Client: ", client);
        updateUser(clientId, client);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        console.log("File: ", file);

        loadImagePreview(file);
        console.log("Preview ", preview);
    };

    const loadImagePreview = (image) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (image) {
            reader.readAsDataURL(image);
        }
        return reader.result;
    };

    return (
        // <div style={{marginBottom: '100px', maxWidth: '1000px'}}>
        <div style={{margin: '0 auto 200px auto', marginBottom: '5rem', maxWidth: '1000px',}}>
            <Row>
                <Col md={12}>
                    <Form.Group controlId="formImagen">
                        <div className="mt-3 image-div">
                            {image ? (
                                <img src={preview} alt="Vista previa" style={{
                                    width: '100px', height: '100px',
                                    borderRadius: '50%', objectFit: 'cover'
                                }}/>
                            ) : (
                                <FaUserCircle size={30}/>
                            )}
                        </div>
                        <Form.Control className={"sm"} type="file" onChange={(e => handleFileChange(e))}
                                      accept="image/*"/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" placeholder="Nombre" value={name}
                                      onChange={(e) => setName(e.target.value)}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formSurname">
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control type="text" placeholder="Apellidos" value={surname}
                                      onChange={(e) => setSurname(e.target.value)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="formAddress">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control type="text" placeholder="Dirección" value={address}
                                      onChange={(e) => setAddress(e.target.value)}/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formBirthday">
                        <Form.Label>Fecha de nacimiento</Form.Label>
                        <Form.Control type="date" value={birthday}
                                      onChange={(e) => setBirthday(e.target.value)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="formId">
                        <Form.Label>Nº Identificación</Form.Label>
                        <Form.Control type="text" placeholder="Nº identificación" value={idNumber} readOnly
                                      disabled/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formTlf">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control type="text" placeholder="Teléfono" value={tlf}
                                      onChange={(e) => setTlf(e.target.value)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" placeholder="Email" value={email}
                                      onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Button variant="primary" className="save-btn-mobile" type="submit"
                            style={{marginTop: '1rem', width: '100%', display: 'inline-block'}}
                            onClick={e => onSubmit(e)}>
                        Guardar
                    </Button>
                </Col>
            </Row>
            {/*<Button variant="primary" className="save-btn float-xl-end" type="submit"
                    style={{position: 'absolute', bottom: '3rem', right: '2rem'}}
                    onClick={e => onSubmit(e)}>
                Guardar
            </Button>*/}
        </div>
    );
}

export default ProfileUser;